import React from "react";
import Stars from "./stars";
const ChamberReviews = () =>{

    // const [isMore, setMore] = useState(false)
    // const handleClick = () => {
    //     return setMore(!isMore)
    // }
        
    const reviewsChamb = [
        {
            name:"Yesica G.",
            date:"Sep 17, 2023",
            text:"All Day Roofing one of the best companies that did my roof Hi quality job Professional I recommended to everybody!",
            stars:5
        },

    ]

    return(
        <section className="w-full py-5">
                <a href="https://www.yelp.com/biz/all-day-roofing-sacramento"
                target="_blank"
                rel="noreferrer"
                className="flex w-[300px] mx-auto justify-center py-5">
                    <img
                    alt="logoyelp"
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fyelp.png?alt=media&token=a6d38340-c9fc-4a26-812f-1093404c0c27"
                   />
                </a> 
                

                {
                    // isMore?
                    // reviewsChamb.map((item, index) =>{
                    //     return(
                    //         <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto bg-[#222] rounded-lg">
                    //         <div className="flex justify-between p-5">
                    //             <div className="flex">
                    //             <img
                    //             src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                    //             alt="userphotodeatult"
                    //              className="w-[50px] shadow-md rounded-[50%]"/>
                    //             <div className="ml-3">
                    //                 <span className="font-medium">{item.name}</span>
                    //                 <Stars 
                    //                 score={item.stars}
                    //                 />
                    //             </div> 
                    //             </div>
                    //             <span className="">{item.date}</span>
    
                    //         </div>
                    //         <div className="p-5">
                    //             <p>{item.text}</p>
                    //         </div>

                    //     </section>
                    //     )
                    // })
                    // :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-[90%] min-h-[100px] mx-auto bg-[#222] rounded-lg text-white">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md shadow-slate-900 rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                {/* <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button> */}
        </section> 
    );

}

export default ChamberReviews