import React from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

const Stars = (props) => {
  return (
    <div className='flex text-yellow-500'>
      {
        [...new Array(5)].map((star, index) => {
          return index < props.score ? <AiFillStar /> : <AiOutlineStar />
        })
      }
    </div>
  )
}

export default Stars